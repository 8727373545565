import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import admin from './modules/admin'
import role from './modules/role'
import permission from './modules/permission'
import notification from './modules/notification'
import report from './modules/report'
import category from './modules/category'
import article from './modules/article'
import binday from './modules/binday'
import service from './modules/service'
import bindetails from './modules/bindetails'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        user,
        notification,
        report,
        admin,
        role,
        permission,
        category,
        article,
        binday,
        service,
        bindetails
    },
    state: {
        controls: {
            showDrawer: true
        }
    },
    mutations: {
        toggleDrawer(state, value) {
            state.controls.showDrawer = value
        }
    }
})
