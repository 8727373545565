import Role from '@/models/Role'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1
        },
        roleDetails: null
    },

    mutations: {
        setRoleList(state, roles) {
            each(roles, role => {
                const exist = find(state.list, { id: role.id })
                if (!exist) {
                    state.list.push(new Role(role))
                }
            })
        },

        clearRoleList(state) {
            state.list = []
        },

        setRoleListMeta(state, meta) {
            state.listMeta = meta
        },

        setRoleDetails(state, role) {
            if (role instanceof Role) {
                state.roleDetails = role
            } else {
                state.roleDetails = new Role(role)
            }
        }
    },

    actions: {
        async getRoles({ commit }, params) {
            const query = Role.page(params.page || 1)

            if (params.search) {
                query.where('search', params.search)
            }

            const res = await query.params({ limit: 20 }).get()
            commit('setRoleList', res.data)
            commit('setRoleListMeta', res.meta)
        },

        async getRoleDetails({ commit }, id) {
            const { data } = await Api.get(`admin/roles/${id}`)
            commit('setRoleDetails', data.data)
        },

        async updateRole({ commit }, formData) {
            const { data } = await Api.put(
                `admin/roles/${formData.id}`,
                formData
            )
            commit('setRoleDetails', data.data)
            return data
        },

        async createRole({ commit }, formData) {
            const { data } = await Api.post(`admin/roles`, formData)
            commit('setRoleDetails', data.data)
            return data
        }
    }
}
