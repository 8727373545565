import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

Vue.filter('formatDatetime', value => {
    return dayjs
        .utc(value)
        .local()
        .format('MMM DD, YYYY hh:mm A')
})
