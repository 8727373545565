import Report from '@/models/Report'
// import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1
        }
    },

    actions: {
        async getReports({ commit }, params) {
            const query = Report.page(params.page || 1)

            if (params.search) {
                query.where('search', params.search)
            }

            const res = await query
                .params({ limit: 20 })
                .include('reporter', 'reason')
                .get()

            commit('setReportsList', res.data)
            commit('setReportsListMeta', res.meta)
        }
    },

    mutations: {
        setReportsList(state, reports) {
            each(reports, report => {
                const exist = find(state.list, { id: report.id })
                if (!exist) {
                    state.list.push(new Report(report))
                }
            })
        },

        clearReportsList(state) {
            state.list = []
        },

        setReportsListMeta(state, meta) {
            state.listMeta = meta
        }
    }
}
