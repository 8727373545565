import Model from './Model'
// javascript library that parses, validates, manipulates, and display dates
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default class Article extends Model {
    resource() {
        return 'interest/articles'
    }

    /**
     * Get article date
     */
    get articleDate() {
        return dayjs
            .utc(this.date)
            .local()
            .format('YYYY-MM-DD')
    }

    get createdDate() {
        return dayjs
            .utc(this.created_at)
            .local()
            .format('YYYY-MM-DD')
    }
}
