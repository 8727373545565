import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        auth,
        {
            path: '/',
            name: 'home',
            redirect: { name: 'users' },
            component: () =>
                import(/* webpackChunkName: "home" */ '@/views/Home'),
            children: [
                {
                    path: 'inbox',
                    name: 'inbox',
                    component: () =>
                        import(
                            /* webpackChunkName: "inbox" */ '@/views/Home/Inbox'
                        )
                },
                {
                    path: 'users',
                    name: 'users',
                    component: () =>
                        import(
                            /* webpackChunkName: "users" */ '@/views/Home/Users'
                        )
                },
                {
                    path: 'users/:id',
                    name: 'user.details',
                    component: () =>
                        import(
                            /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
                        )
                },
                {
                    path: 'content',
                    name: 'content',
                    component: () =>
                        import(
                            /* webpackChunkName: "content" */ '@/views/Home/Content'
                        )
                },
                {
                    path: 'notification',
                    name: 'notification',
                    component: () =>
                        import(
                            /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
                        )
                },
                {
                    path: 'settings',
                    name: 'settings',
                    component: () =>
                        import(
                            /* webpackChunkName: "settings" */ '@/views/Home/Settings'
                        )
                },
                {
                    path: 'reports',
                    name: 'reports',
                    component: () =>
                        import(
                            /* webpackChunkName: "reports" */ '@/views/Home/Reports'
                        )
                },

                {
                    path: 'admins',
                    name: 'admins',
                    component: () =>
                        import(
                            /* webpackChunkName: "admins" */ '@/views/Home/Admins'
                        )
                },
                {
                    path: 'admins/create',
                    name: 'create.admin',
                    component: () =>
                        import(
                            /* webpackChunkName: "admins" */ '@/views/Home/Admins/form.vue'
                        )
                },
                {
                    path: 'admins/:id',
                    name: 'admin.details',
                    component: () =>
                        import(
                            /* webpackChunkName: "admins" */ '@/views/Home/Admins/form.vue'
                        )
                },
                {
                    path: 'roles',
                    name: 'roles',
                    component: () =>
                        import(
                            /* webpackChunkName: "admins" */ '@/views/Home/Roles'
                        )
                },
                {
                    path: 'roles/create',
                    name: 'create.role',
                    component: () =>
                        import(
                            /* webpackChunkName: "admins" */ '@/views/Home/Roles/form.vue'
                        )
                },
                {
                    path: 'roles/:id',
                    name: 'role.details',
                    component: () =>
                        import(
                            /* webpackChunkName: "admins" */ '@/views/Home/Roles/form.vue'
                        )
                },
                {
                    path: 'account/settings',
                    name: 'account.settings',
                    component: () =>
                        import(
                            /* webpackChunkName: "settings" */ '@/views/Home/Settings/account'
                        )
                },
                /**
                 * Interest Article Routes
                 */
                {
                    path: 'interests',
                    name: 'interests'
                },
                {
                    path: 'interests/categories',
                    name: 'categories',
                    component: () =>
                        import(
                            /* webpackChunkName: "categories" */ '@/views/Home/Interests/Categories/index.vue'
                        )
                },
                {
                    path: 'interests/articles',
                    name: 'articles',
                    component: () =>
                        import(
                            /* webpackChunkName: "articles" */ '@/views/Home/Interests/Articles/index.vue'
                        )
                },
                {
                    path: 'interests/articles/create',
                    name: 'create.article',
                    component: () =>
                        import(
                            /* webpackChunkName: "articles" */ '@/views/Home/Interests/Articles/form.vue'
                        )
                },
                {
                    path: 'interests/articles/:id',
                    name: 'article.details',
                    component: () =>
                        import(
                            /* webpackChunkName: "articles" */ '@/views/Home/Interests/Articles/form.vue'
                        )
                },
                {
                    path: 'bindays',
                    name: 'bindays',

                    component: () =>
                        import(
                            /* webpackChunkName: "bindays" */ '@/views/Home/BinDays/index.vue'
                        )
                },
                {
                    path: 'buttons',
                    name: 'buttons',

                    component: () =>
                        import(
                            /* webpackChunkName: "buttons" */ '@/views/Home/Services/index.vue'
                        )
                },
                {
                    path: 'bindetails',
                    name: 'bindetails',

                    component: () =>
                        import(
                            /* webpackChunkName: "bindays" */ '@/views/Home/BinDetails/index.vue'
                        )
                }
            ]
        }
    ]
})
