import Article from '@/models/Article'
import Api from '@/services/api'
import { each, find } from 'lodash'
import moment from 'moment'

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1
        },
        articleDetails: null
    },

    mutations: {
        setArticleList(state, articles) {
            each(articles, article => {
                const exist = find(state.list, { id: article.id })
                if (!exist) {
                    state.list.push(new Article(article))
                }
            })
        },

        clearArticleList(state) {
            state.list = []
        },

        setArticleListMeta(state, meta) {
            state.listMeta = meta
        },

        setArticleDetails(state, article) {
            if (article instanceof Article) {
                state.articleDetails = article
            } else {
                state.articleDetails = new Article(article)
            }
        }
    },

    actions: {
        async getArticles({ commit }, params) {
            const query = Article.page(params.page || 1).include('category')

            if (params.search) {
                query.where('search', params.search)
            }

            const res = await query.params({ limit: 20 }).get()
            commit('setArticleList', res.data)
            commit('setArticleListMeta', res.meta)
        },

        async getTags({ commit }) {
            const res = await Api.get('/article/tags')
            return res.data
        },

        async getArticleDetails({ commit }, id) {
            const { data } = await Api.get(`interest/articles/${id}`)

            data.data.date = moment(data.data.date).format('YYYY-MM-DD')
            // separate date and date time for both start and end date
            const startDate =
                data.data.start_date || new Date().toISOString().substr(0, 10)

            const endDate =
                data.data.end_date || new Date().toISOString().substr(0, 10)

            if (startDate !== '') {
                data.data.start_date = moment
                    .utc(startDate)
                    .format('YYYY-MM-DD')
                data.data.time_start = moment.utc(startDate).format('HH:mm')
            } else {
                data.data.start_date = ''
                data.data.time_start = ''
            }

            if (endDate !== '') {
                data.data.end_date = moment.utc(endDate).format('YYYY-MM-DD')
                data.data.time_end = moment.utc(endDate).format('HH:mm')
            } else {
                data.data.end_date = ''
                data.data.time_end = ''
            }

            // format data to display
            commit('setArticleDetails', data.data)
        },

        splitDateTime(datetimeValue) {
            var dateTime = moment(datetimeValue)
                .format('DD-MM-YYYY HH:mm:ss')
                .split(' ')
            return dateTime
        },

        async updateArticle({ commit }, formData) {
            const { data } = await Api.post(
                `interest/articles/${formData.get('id')}?_method=put`,
                formData
            )
            commit('setArticleDetails', data.data)
            return data
        },

        async createArticle({ commit }, formData) {
            const { data } = await Api.post(`interest/articles`, formData)
            commit('setArticleDetails', data.data)
            return data
        }
    }
}
