import Api from '@/services/api'
export default {
    namespaced: true,
    state: {
        list: []
    },

    actions: {
        async getPermissions({ commit }) {
            const res = await Api.get('/admin/permissions')
            return res.data
        }
    }
}
