import Api from '@/services/api'

export default {
    namespaced: true,
    statu: {
        list: []
    },

    actions: {
        async getCategories({ commit }) {
            const res = await Api.get('/interests/categories')
            return res.data
        }
    }
}
