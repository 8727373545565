import Admin from '@/models/Admin'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1
        },
        adminDetails: null
    },

    mutations: {
        setAdminList(state, admins) {
            each(admins, admin => {
                const exist = find(state.list, { id: admin.id })
                if (!exist) {
                    state.list.push(new Admin(admin))
                }
            })
        },

        clearAdminList(state) {
            state.list = []
        },

        setAdminListMeta(state, meta) {
            state.listMeta = meta
        },

        setAdminDetails(state, admin) {
            if (admin instanceof Admin) {
                state.adminDetails = admin
            } else {
                state.adminDetails = new Admin(admin)
            }
        },

        setSelectedAdminAvatar(state, avatar) {
            if (avatar instanceof Avatar) {
                state.adminDetails.avatar = avatar
            } else {
                state.adminDetails.avatar = new Avatar(avatar)
            }
        },

        clearAdminDetails(state) {
            state.adminDetails = null
        },

        adminAvatarRemoved(state) {
            state.adminDetails.avatar = null
        }
    },

    actions: {
        async getAdmins({ commit }, params) {
            const query = Admin.page(params.page || 1).include('avatar')

            if (params.search) {
                query.where('search', params.search)
            }

            const res = await query.params({ limit: 20 }).get()

            commit('setAdminList', res.data)
            commit('setAdminListMeta', res.meta)
        },

        async getAdminDetails({ commit }, id) {
            const { data } = await Api.get(`admin/admins/${id}`)
            commit('setAdminDetails', data.data)
        },

        async changeAvatar({ commit }, formData) {
            const { data } = await Api.post(
                `admin/admins/${formData.get('admin_id')}/avatar`,
                formData
            )
            commit('setSelectedAdminAvatar', data.data)
        },

        async updateAdmin({ commit }, formData) {
            const { data } = await Api.put(
                `admin/admins/${formData.id}`,
                formData
            )
            commit('setAdminDetails', data.data)
            return data
        },

        async createAdmin({ commit }, formData) {
            const { data } = await Api.post(`admin/admins`, formData)
            commit('setAdminDetails', data.data)
            return data
        },

        async blockUser({ commit }, { id, action }) {
            const { data } = await Api.post(`admin/admins/${id}/${action}`)
            commit('setAdminDetails', data.data)
        },

        async resetPassword({ commit }, { id }) {
            const { data } = await Api.post(
                `admin/admins/${id}/reset-password/default`
            )
            commit('setAdminDetails', data.data)
        },

        async exportToExcel() {
            const file = await Api.get('/admins/export', {
                responseType: 'blob'
            })
            return file
        }
    }
}
