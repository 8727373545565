import BinDay from '@/models/BinDay'
import { each, find } from 'lodash'
import Api from '@/services/api'
import dayjs from 'dayjs'
export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1
        },
        importing: null,
        latestUpdate: null,
        latestUpdatedBy: null
    },
    mutations: {
        setBinDayList(state, bindays) {
            each(bindays, binday => {
                const exist = find(state.list, { id: binday.id })

                state.latestUpdate = dayjs(binday.latest_update).format(
                    'hh:mm A DD/MM/YYYY'
                )

                state.latestUpdatedBy = binday.creator

                if (!exist) {
                    state.list.push(new BinDay(binday))
                }
            })
        },

        clearBinDayList(state) {
            state.list = []
        },

        setBinDayListMeta(state, meta) {
            state.listMeta = meta
        },

        setImporting(state, importing) {
            state.importing = importing
        }
    },
    actions: {
        async getBinDays({ commit }, params) {
            const query = BinDay.page(params.page || 1)
            if (params.search) {
                query.where('search', params.search)
            }

            const res = await query.params({ limit: 20 }).get()

            if (res.importing) {
                commit('setImporting', res.importing)
            }

            commit('setBinDayList', res.data)
            commit('setBinDayListMeta', res.meta)
        },

        async uploadCsvFile({ commit }, formData) {
            await Api.post(`admin/bindays/import`, formData)
        },

        async exportToExcel() {
            const { data } = await Api.get('admin/bindays/download-url')
            return data
        }
    }
}
