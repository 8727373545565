import BinDetails from '@/models/BinDetails'
import { each, find } from 'lodash'
import Api from '@/services/api'
export default {
    namespaced: true,
    state: {
        list: [],
        showUpdateBinDetailsDialog: false,
        showWhatWeCollectDialog: false,
        showWhatWeDoNotcollectDialog: false,
        showMoreInfoSectionDialog: false,
        binId: '',
        updateSuccessful: false,
        openSnackbar: false,
        binDetailsSidebarTitle1: '',
        binDetailsSidebarTitle2: '',
        binDetailsSidebarTitle3: ''
    },
    mutations: {
        setBinsList(state, bins) {
            each(bins, bin => {
                const exist = find(state.list, { id: bin.id })

                if (!exist) {
                    state.list.push(new BinDetails(bin))
                }
            })
        },

        changeBinsList(state, data) {
            const bin = find(state.list, { id: data.id })

            if (bin) Object.assign(bin, new BinDetails(data))
        },

        toggleShowUpdateBinDetailsDialog(state, value) {
            state.showUpdateBinDetailsDialog = value
        },

        toggleShowWhatWeCollectDialog(state, value) {
            state.showWhatWeCollectDialog = value
        },

        toggleShowWhatWeDoNotcollectDialog(state, value) {
            state.showWhatWeDoNotcollectDialog = value
        },

        toggleShowMoreInfoSectionDialog(state, value) {
            state.showMoreInfoSectionDialog = value
        },

        setBinId(state, value) {
            state.binId = value
        },

        toggleUpdateSuccessful(state, value) {
            setTimeout(() => {
                state.updateSuccessful = false
            }, 3000)

            state.updateSuccessful = value
        },

        toggleSnackbar(state, value) {
            state.openSnackbar = value
        },

        updateBinDetailsSidebarTitle1(state, value) {
            state.binDetailsSidebarTitle1 = value
        },

        updateBinDetailsSidebarTitle2(state, value) {
            state.binDetailsSidebarTitle2 = value
        },

        updateBinDetailsSidebarTitle3(state, value) {
            state.binDetailsSidebarTitle3 = value
        }
    },
    actions: {
        async getBinsList({ commit }) {
            const res = await BinDetails.get()

            commit('setBinsList', res.data)
        },
        async toggleVisibility({ commit, state }, params) {
            const { data } = await Api.get(
                `admin/bins/${params.id}/toggle-visibility`
            )

            commit('changeBinsList', data.data)
        },
        async updateBinInformation({ commit, state }, params) {
            let fd = new FormData()

            fd.append('_method', 'PUT')

            if (params.hasOwnProperty('description'))
                fd.append('description', params.description)
            if (params.hasOwnProperty('whatWeCollect'))
                fd.append('we_collect', params.whatWeCollect)
            if (params.hasOwnProperty('whatWeDoNotCollect'))
                fd.append('do_not_collect', params.whatWeDoNotCollect)
            if (params.hasOwnProperty('linkLabel'))
                fd.append('more_info_link_label', params.linkLabel)
            if (params.hasOwnProperty('linkUrl'))
                fd.append('more_info_link_url', params.linkUrl)
            if (params.hasOwnProperty('moreInfoDescription'))
                fd.append('more_info_description', params.moreInfoDescription)
            if (
                params.hasOwnProperty('icon') &&
                params['icon'] &&
                params.icon[0] instanceof File
            ) {
                fd.append('icon', params.icon[0])
            }
            if (
                params.hasOwnProperty('moreInfoIcon') &&
                params['moreInfoIcon'] &&
                params.moreInfoIcon[0] instanceof File
            ) {
                fd.append('more_info_icon', params.moreInfoIcon[0])
            }

            const { data } = await Api.post(`admin/bins/${params.id}`, fd, {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            await commit('changeBinsList', data.data)

            return data
        },
        getBinInformation({ commit, state }, params) {
            return find(state.list, { id: params.id })
        }
    }
}
