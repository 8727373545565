import Service from '@/models/Service'
import { each, find } from 'lodash'
import Api from '@/services/api'

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1
        },
        dialogShown: false,
        confirmDeleteShown: false,
        deletingService: null,
        deleting: false,
        editingService: null,
        rearranging: false
    },
    mutations: {
        setServiceList(state, services) {
            each(services, service => {
                const exist = find(state.list, { id: service.id })

                if (!exist) {
                    state.list.push(new Service(service))
                }
            })
        },
        reorderServiceList(state, services) {
            state.list = services
        },
        setService(state, service) {
            const exist = find(state.list, { id: service.id })
            if (exist) {
                Object.assign(exist, service)
            } else {
                state.list.push(new Service(service))
            }
        },

        clearServiceList(state) {
            state.list = []
        },

        setServiceListMeta(state, meta) {
            state.listMeta = meta
        },
        setDialogShown(state, shown) {
            state.dialogShown = shown
        },
        setConfirmDeleteShown(state, shown) {
            state.confirmDeleteShown = shown
        },
        setDeletingService(state, service) {
            state.deletingService = service
        },
        setDeleting(state, deleting) {
            state.deleting = deleting
        },
        setEditingService(state, service) {
            state.editingService = service
        },
        setRearranging(state, rearranging) {
            state.rearranging = rearranging
        }
    },
    actions: {
        async getServices({ commit }, params) {
            const query = Service.page(params?.page || 1)
            if (params?.search) {
                query.where('search', params?.search)
            }

            const res = await query.params({ limit: 20 }).get()

            commit('setServiceList', res.data)
            commit('setServiceListMeta', res.meta)
        },

        async updateService({ commit, state }, formData) {
            const { data } = await Api.post(
                `admin/services/${state.editingService.id}?_method=put`,
                formData
            )

            commit('setService', data.data)
            return data
        },

        async createService({ commit }, formData) {
            const { data } = await Api.post(`admin/services`, formData)

            commit('setService', data.data)
            return data
        },

        async deleteService({ commit, dispatch, state }) {
            commit('setDeleting', true)
            const { data } = await Api.delete(
                `admin/services/${state.deletingService.id}`
            ).finally(() => {
                commit('setDeleting', false)
            })

            commit('setConfirmDeleteShown', false)

            commit('clearServiceList')
            dispatch('getServices')

            return data
        },

        async rearrangeServicesSequence({ commit, state }) {
            commit('setRearranging', true)
            const { data } = await Api.post(
                `admin/rearrange-services-sequence`,
                {
                    service_ids: state.list.map(service => service.id)
                }
            ).finally(() => {
                commit('setRearranging', false)
            })

            return data
        }
    }
}
