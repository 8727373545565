import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class BinDetails extends Model {
    resource() {
        return 'admin/bins'
    }

    get updatedDate() {
        return this.updated_at
            ? dayjs
                  .utc(this.updated_at)
                  .local()
                  .format('MM/DD/YYYY')
            : '-'
    }
}
