import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class User extends Model {
    resource() {
        return 'admin/report/users'
    }

    get addedDate() {
        return dayjs
            .utc(this.created_at)
            .local()
            .format('MMM DD, YYYY hh:mm A')
    }
}
